.list-bar-block {
    .list-bar-card-text-container {
        position: relative;
    }
    .flow-chart-card {
        max-width: 1110px;
        width: 100%;
        .card {
            border: initial;
            border-radius: initial;
        }
    }
    .flow-chart-number {
        width: 50px;
        height: 50px;
        background-color: black;
        color: white;
        position: absolute;
        top: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .number-connected {
        height: 50px;
        width: 17px;
        background-color: black;
        margin-top: -65px;
        &.connection-shown {
            display: block;
            overflow-y: hidden;
        }
        &.connection-hidden {
            display: none;
        }
    }
}

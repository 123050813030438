.horizontal-flow-chart {
   .card {
       border: initial;
       .card-heading-text {
           width: 100%;
       }
       ol, ul {
           padding-left: 3rem;
       }
   }
    .flow-chart-number {
        position: initial;
        top: initial;
    }
    .flow-line {
        height: 16px;
        background: black;
        position: absolute;
        top: 42px;
        left: 0;
        width: 100%;
    }
    .flow-chart-bottom-text {
        padding-left: 70px;
        padding-right: 70px;
        @media screen and (max-width: 575px) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}

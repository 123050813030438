.max-width-1496px {
    max-width: 1496px;
}

.min-height-50vh {
    min-height: 50vh;
}

.min-height-100vh {
    min-height: 100vh;
}

.min-height-md-100vh {
    @media screen and (min-width: 768px) {
        min-height: 100vh;
    }
}

.h-0 {
    height: 0;
}

.h-xl-435px {
    @media screen and (min-width: 1200px) {
        height: 435px !important;
    }
}

.height-50vh {
    height: 50vh;
}

.mt-50px {
    margin-top: 50px;
}

.mt-100px {
    margin-top: 100px;
}

.mb-70px {
    margin-bottom: 70px;
}

.px-576-1550-3rem {
    @media screen and (min-width: 576px) and (max-width: 1550px) {
        padding-right: 3rem;
        padding-left: 3rem;
    }
}

.px-0-576-initial {
    @media screen and (max-width: 575px) {
        padding-right: initial;
        padding-left: initial;
    }
}

.px-0-576-2rem {
    @media screen and (max-width: 399px) {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    @media screen and (min-width: 400px) and (max-width: 575px) {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}

.max-width-1300px {
    max-width: 1300px;
}

.max-width-1100px {
    max-width: 1100px;
}

.max-width-800px-xxl {
    @media screen and (max-width: 1399px) {
        max-width: 800px;
    }
}

.p-inherit {
    padding: inherit;
}

.gx-150px {
    @media screen and (min-width: 768px) {
        --bs-gutter-x: 75px;
    }
    @media screen and (min-width: 1200px) {
        --bs-gutter-x: 100px;
    }
    @media screen and (min-width: 1440px) {
        --bs-gutter-x: 150px;
    }
}

.min-height-456px {
    min-height: 456px;
}

// padding range for stream blocks

.pt-space-0 {
    padding-top: 0;
    &.icons-texts-links-container {
        padding-top: 1px;
    }
}

.pt-space-1 {
    padding-top: 3rem;
}

.pt-space-2 {
    padding-top: 6rem;
}

.pt-space-3 {
    padding-top: 9rem;
}

.pb-space-0 {
    padding-bottom: 0;
    &.icons-texts-links-container {
        padding-bottom: 1px;
    }
}

.pb-space-1 {
    padding-bottom: 3rem;
}

.pb-space-2 {
    padding-bottom: 6rem;
}

.pb-space-3 {
    padding-bottom: 9rem;
}



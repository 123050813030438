.three-squares-without-icons-container {
    .three-cards-without-icon {
        background-color: #ede4de;
        border: initial;
        border-radius: initial;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        padding: 2.5rem;
        @media screen and (max-width: 575px) {
            padding: 2.5rem 1.2rem;
        }
    }

    #left-outer-bg-line,
    #right-outer-bg-line,
    #left-inner-bg-line,
    #right-inner-bg-line {
        @media screen and (max-width: 1199px) {
            display: none;
        }
        display: inline-block;
        position: absolute;
        border-bottom: 20px solid #ede4de;
    }

    #left-outer-bg-line, #right-outer-bg-line {
        width: 33%;
        z-index: 1;
        @media screen and (min-width: 3500px) {
            width: 43%;
        }
    }

    #left-outer-bg-line {
        top: 75px;
        left: 0;
    }

    #right-outer-bg-line {
        bottom: 75px;
        right: 0;
    }

    #left-inner-bg-line {
        top: 125px;
        left: 0;
        width: 50%;
        z-index: 1;
        margin-left: calc(var(--bs-gutter-x) * .5);
        &.center {
            top: 50%;
        }
    }

    #right-inner-bg-line {
        bottom: 125px;
        right: 0;
        width: 50%;
        z-index: 1;
        margin-right: calc(var(--bs-gutter-x) * .5);
    }

    &.with-border {
        .three-cards-without-icon {
            background-color: white;
            border: 20px solid #e4edec;
            border-radius: 40px;

        }

        #left-outer-bg-line,
        #right-outer-bg-line,
        #left-inner-bg-line,
        #right-inner-bg-line {
            border-bottom: 20px solid #e4edec;
        }

        #left-inner-bg-line {
            top: 160px;
        }

        #right-inner-bg-line {
            bottom: 160px;
        }
    }
}

.text-embed-into-image-container {
    position: relative;
    .map-image {
        width: 100vw;
        height: 100vh;
        margin: auto;
        object-fit: cover;
    }
    .map-content-wrapper {
        position: absolute;
        top: 20%;
        transform: translate(-50%, 0);
        @media screen and (min-width: 768px) {
            transform: translate(-50%, 50%);
        }
        left: 50%;
        width: 100%;
        z-index: 10000;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: rem(24);
        padding: 0 24px;
        @media screen and (max-width: 768px) {
            font-size: rem(20);
        }
        .map-content-wrapper-heading {
            text-align: center;
            font-size: rem(60);
            line-height: rem(70);
            font-family: "PPAgrandirText-Bold", sans-serif;
            @media screen and (max-width: 575px) {
                font-size: rem(36);
                line-height: rem(40);
            }
            @media screen and (min-width: 576px)  and (max-width: 1200px) {
                font-size: rem(45);
                line-height: rem(63);
            }
        }
        .map-content-wrapper-text {
            text-align: center;
        }
        &.map-content-wrapper-dark {
            color: $col-black-1;
        }
        &.map-content-wrapper-bright {
            color: $col-white;
        }
    }
}

.card-wrapper {
    &:nth-child(1) {
    }
    &:nth-child(4) {
    }
}

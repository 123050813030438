footer {
    word-break: break-word;
    .footer-titles {
        font-size: rem(18);
        line-height: rem(20);
        font-weight: 700;
    }
    .nav-link {
        font-size: rem(14);
        line-height: rem(24);
        color: $col-black-1;
        &:hover {
            opacity: 75%;
            color: $col-black-1;
        }
    }
    .top-footer {
        color: $col-black-1;
        p, .contact-email {
            font-size: rem(18);
            line-height: rem(26);
        }
        .contact-email {
            color: $col-green;
            text-decoration: initial;
            &:hover {
                color: $col-green;
                opacity: 75%;
            }
        }
    }

    .bottom-footer {
        background-color: $col-black-1;
        color: white;
        font-size: rem(16);
        line-height: rem(21);
    }
    .footer-logo {
        width: rem(24);
    }
    .footer-logo-url {
        position: absolute;
        top: 0;
        left: -50px;
        display: none;
        @media screen and (min-width: 1700px) {
            display: block;
        }
    }
    .footer-logo-url-mobile {
        display: none;
        @media screen and (max-width: 1699px) {
            display: block;
        }
    }
}

.bg-beige {
    background-color: $col-beige !important;
}

.bg-dark-grey {
    background-color: $col-dark-grey !important;
}

.bg-black {
    background-color: $col-black-1 !important;
}

.bg-green {
    background-color: $col-green !important;
}



// Power Sustainable Energy Infrastructure Partnership section
.logo-partner {
    max-height: 70px;
    img {
        height: auto;
        max-width: 100%;
    }
}
.arrow-right-partner {
    height: 50px;
    img {
        height: 100%;
        width: 100%;
    }
}


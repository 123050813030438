
nav {
    .offcanvas {
        padding-left: 80px;
        padding-top: 50px;
        pointer-events: auto;
        touch-action: auto;
    }

    .offcanvas.offcanvas-end {
        @media (max-width: 575px) {
            width: 100%;
            border-left: initial;
        }
    }

    .nav-sidebar-close-link{
        position: absolute;
        //left: 30px;
        //bottom: 40px;
        width: 20px;
        left: initial;
        bottom: initial;
        right: 30px;
        top: 65px;
    }

    .nav-sidebar-close-btn {
        background: url("../img/close_icon.svg");
        background-repeat: no-repeat;
        height: 19px;
        width: auto;
        pointer-events: auto;
        touch-action: auto;
    }

}

@media screen and (max-width: 767px) {
    .template-home-page {
        nav {
            .offcanvas {
                padding-top: 30px;
                padding-left: 50px;
            }
            .nav-sidebar-close-link {
                left: initial;
                bottom: initial;
                right: 30px;
                top: 50px;
            }
        }
    }
}

/* animations */
@keyframes slideInFromLeft {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideInFromLeftNavButtons {
    0% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes animatePop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

/* hero page */

.hero-section-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.background-img-wrapper {
    width: 100vw;
    height: 100vh;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1000;
    animation: slideInFromLeft 3s ease-in-out;
    .background-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.musk-img {
    width: 100vw;
    height: 100vh;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -101;
}

.hero-section {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &.first-hero-section {
        gap: 15vh;
        @media only screen and (max-width: $screen-lg) {
            gap: 13vh;
        }
    }
    &.second-hero-section {
        gap: 22vh;
        position: relative;
        @media only screen and (max-width: $screen-lg) {
            gap: 15vh;
        }
    }
}

/* header section */

.header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logo-wrapper {
        width: 200px;
        height: 45px;
        cursor: pointer;
        animation: animatePop 1s ease-in-out;
        .log-black {
        }
    }
    .hamburger-wrapper {
        width: 35px;
        height: 35px;
        cursor: pointer;
        animation: animatePop 1s ease-in-out;
        display: flex;
        align-self: flex-end;
        .navigation-black {
        }
    }
}

/* hero content section */

.content-section-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    &.dark {
        color: $col-black-1;
        h1, h1 a {
            color: $col-black-1;
        }
    }
    &.bright {
        color: $col-white;
        h1, h1 a {
            color: $col-white;
        }
    }
}

.content-section-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 300px;
}
.content-section-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &.first-hero-content-right {
        justify-content: center;
    }
    &.second-hero-content-right {
        justify-content: flex-start;
    }
}

.redirect-button-container {
    max-width: 85%;
}

/* hero content heading */

.heading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 6vh;
    &.dark {
        color: $col-black-1;
    }
    &.bright {
        color: $col-white;
    }
}
.heading-text {
    animation: animatePop 0.7s ease-in-out;
    @media only screen and (max-width: $screen-lg) {
        font-size: 35px;
        line-height: 35px;
    }
}

.sub-heading-text {
    animation: animatePop 1s ease-in-out;
    max-width: 600px;
    font-family: "Roboto", sans-serif;
    @media only screen and (max-width: $screen-lg) {
        font-size: 18px;
        line-height: 25px;
    }
}

.heading-text-bottom {
    &.heading-bottom-bright {
        color: $col-black-1;

    }
    &.heading-bottom-dark {
        color: $col-white;
    }
}

.heading-dot {
    color: $col-red;
    margin-left: 4px;
}

/* first hero content down arrow */

#go-top-btn,  #go-bottom-btn-lg-xl {
    display: inline-flex;
    justify-content: center;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    margin-top: 2rem;
    pointer-events: auto;
    touch-action: auto;
    img {
        width: 58px;
        height: 58px;
        @media screen and (max-width: 1400px) {
            width: 45px;
            height: 45px;
        }
    }
    @media screen and (max-height: 576px) {
        display: none;
    }
}

#go-top-btn, #go-bottom-btn, #go-bottom-btn-lg-xl {
    position: absolute;
    right: 0;
    bottom: 40px;
    rotate: 180deg;
    display: none;
}

@media screen and (max-width: 1600px) {
    #go-bottom-btn-lg-xl {
        display: inline-flex;
        rotate: initial;
    }
    #go-bottom-btn {
        display: none;
    }
}

@media screen and (max-width: 575px) {
    #go-top-btn, #go-bottom-btn-lg-xl {
        display: none;
    }

}

/* first hero content up arrow */

.arrow-up-btn {
    z-index: 1000000;
    top: -135px;
    right: 0;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    font-size: 24px;
    border: none;
    background-color: $col-gray-2;
    color: $col-black-1;
    padding-top: 4px;
    cursor: pointer;
    &:hover {
        color: $col-red;
    }
    @media only screen and (max-width: $screen-md) {
        top: -85px;
        display: none;
    }
}

/* hero page nav buttons */

.nav-button-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.button-wrapper-nav {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px 0;
    gap: 2%;
    flex: 25%;
    @media only screen and (max-width: $screen-lg) {
        flex: 50%;
        padding: 0;
        gap: 10px;
    }
    a {
        text-decoration: initial;
    }
}

.nav-button {
    border: none;
    padding: 10px 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: 0.3s;
    min-width: 300px;
    min-height: 55px;
    font-size: 18px;
    font-family: "PPAgrandirText-Bold", sans-serif;

    @media only screen and (max-width: $screen-lg) {
        padding: 5px 10px;
    }
    @media only screen and (max-width: $screen-sm) {
        gap: 6px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        min-width: 160px;
        min-height: 90px;
        width: 170px;
        height: 80px;
        span {
            line-height: 20px;
        }
    }

    &.with-background {
        &:nth-child(1) {
            animation: animatePop 1.4s ease-in-out;
        }
        &:nth-child(2) {
            animation: animatePop 1.3s ease-in-out;
        }
        &:nth-child(3) {
            animation: animatePop 1.2s ease-in-out;
        }
        &:nth-child(4) {
            animation: animatePop 1s ease-in-out;
        }
        &:hover {
            .button-icon-black {
                display: none;
            }
            .button-icon-red {
                display: flex;
                align-self: center;
            }
        }

    }
    &.bg-bright {
        color: $col-white;
        background-color: $col-black-1 !important;
    }
    &.bg-dark {
        color: $col-black-1;
        background-color: $col-white !important;
    }
    &.no-background {
        &:hover {
            .button-icon-black {
                display: none;
            }
            .button-icon-red {
                display: flex;
                align-self: center;
            }
        }
        @media only screen and (max-width: $screen-lg) {
            padding-top: 0;
        }
    }
    &.no-bg-bright {
        color: $col-white;
        background-color: transparent;
    }
    &.no-bg-dark {
        color: $col-black-1;
        background-color: transparent;
    }
    .nav-button-icon {
        width: 24px;
        height: 24px;
        img {
            width: 24px;
            height: 24px;
        }
        &.button-icon-black {
            display: flex;
            align-self: center;
        }
        &.button-icon-red {
            display: none;
        }
    }
}

.nav-button-right-arrow {
    width: 55px;
    height: 55px;
    border: none;
    padding: 0;
    animation: animatePop 0.8s ease-in-out;
    img {
        width: 55px;
        height: 55px;
    }
    @media only screen and (max-width: $screen-lg) {
        display: none;
    }
}

/*  hero page about us button */

.about-us-button {
    font-family: "PPAgrandirText-Bold", sans-serif;
    width: 200px;
    height: 45px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 1rem;
    transition: 0.3s;
    animation: slideInFromLeftNavButtons 1s ease-in-out;
    &:hover {
        .arrow-right-bright {
            background: url("../img/close_icon.svg");
            background-repeat: no-repeat;
            height: 16px;
            width: 19px;
        }
    }
    &.button-dark {
        color: $col-white;
        background: $col-black-1;
    }
    &.button-bright {
        color: $col-black-1;
        background: $col-white;
    }
    .arrow-right-bright {
        background: url("../img/icon_arrow_black.svg");
        background-repeat: no-repeat;
        height: 16px;
        width: 19px;
    }
    .arrow-right-dark {
        background: url("../img/icon_arrow_white_right.svg");
        background-repeat: no-repeat;
        height: 16px;
        width: 19px;
    }
}

/* footer styles */

.footer-wrap {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    .footer-top {
        background-color: $col-gray-2;
        .footer-top-content {
            max-width: 1400px;
            margin: auto;
            padding: 80px 0;
            @media only screen and (max-width: $screen-sm) {
                max-width: 95vw;
                padding: 20px 0;
            }
            @media only screen and (max-width: $screen-lg) {
                max-width: 90vw;
                padding: 20px 0;
            }
            .footer-logo {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                gap: 10px;
                padding: 0;
                img {
                    width: 20px;
                    height: 40px;
                }
                .footer-paragraph {
                    max-width: 400px;
                    margin: 0;
                    font-size: 14px;
                    .footer-text {
                        margin-bottom: 0;
                    }
                    .contact {
                        color: $col-green;
                        text-decoration: none;
                    }
                }
            }
            .footer-content {
                .footer-content-heading {
                    font-size: 18px;
                }
                .footer-content-text {
                    font-size: 12px;
                }
            }
        }
    }
    .footer-bottom {
        background-color: $col-black-1;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .footer-bottom-content {
            max-width: 1490px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            color: $col-white;
            font-weight: 300;
            @media only screen and (max-width: $screen-lg) {
                max-width: 85vw;
            }
        }
    }
}

/* slider style */

.nav-dots {
    cursor: pointer;
}
.nav-dot {
    width: 12px;
    height: 12px;
    border: none;
    border-radius: 50%;
    list-style: none;
    background: $col-white;
    transition: all 0.3s;
    display: none;
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
    &:hover,
    &.is-active {
        background: red;
        border: 1px solid $col-white;
    }
}

// Vertical scroll snapping

#scroll-snapping-wrapper {
    display: flex;
}

.logo-black {
    background-image: url("../img/logo_black.svg");
    background-repeat: no-repeat;
    height: -webkit-fill-available;
    position: absolute;
    left: 12px;
    -webkit-transition: background-image 0.4s ease-in-out;
    transition: background-image 0.4s ease-in-out;
    display: inline-block;
    height: 100%;
    max-height: rem(60);
    @media only screen and (max-width: 1700px){
        max-height: rem(36);
    }


}
.navigation-black {
    background-image: url("../img/Icon_menu_black.svg");
    background-repeat: no-repeat;
    height: -webkit-fill-available;
    position: absolute;
    right: 12px;
    -webkit-transition: background-image 0.4s ease-in-out;
    transition: background-image 0.4s ease-in-out;
    display: inline-block;
    height: 100%;
}

.bright {
    .logo-black {
        background-image: url("../img/logo_white.svg");
        background-repeat: no-repeat;
    }
    .navigation-black {
        background-image: url("../img/Icon_menu_white.svg");
        background-repeat: no-repeat;
    }
}

#scroll-snapping-container {
    max-height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-snap-stop: normal;
    scroll-behavior: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    overflow-anchor: none;
    overflow-x: hidden;

    #home-heading, #home-text {
        color: $col-black-1;
    }

    #home-heading span {
        position: absolute;
    }
}

#scroll-snapping-container, #button-block-container {

    #redirect-btn, .redirect-btn {
        color: $col-beige;
        background-color: $col-black-1;
        display: none;
        flex-direction: row;
        align-items: center;
        text-decoration: initial;
        padding: 15px 40px 15px 20px;
        cursor: pointer;
        margin-top: 2rem;
        pointer-events: auto;
        touch-action: auto;
        &.bright {
            color: $col-black-1;
            background-color: rgba(255,255,255,.8);
            #redirect-btn-icon {
                background-image: url("../img/icons_platforms/right_arrow_black.svg");
                background-repeat: no-repeat;
            }
        }
        #redirect-btn-icon, .redirect-btn-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-top: 4px;
            background-image: url("../img/icons_platforms/right_arrow_beige.svg");
            background-repeat: no-repeat;
            position: relative;
            padding-right: 20px;
            left: 0;
            transition: left 0.5s;
            @media screen and (max-width: 575px) {
                display: none;
            }
            @media screen and (max-height: 699px) {
                display: none;
            }

        }
        &:hover {
            #redirect-btn-icon, .redirect-btn-icon {
                left: 10px;
            }
        }
        #redirect-btn-title, .redirect-btn-title {
            font-size: rem(20);
            line-height: rem(28);
            font-family: "PPAgrandirText-Bold", sans-serif;
            display: inline-block;
            @media screen and (min-width: 576px) {
                padding-left: 18px;
            }
        }
        @media screen and (max-width: 1400px) {
            padding: 12px 40px 12px 20px;
            #redirect-btn-title, .redirect-btn-title {
                font-size: rem(18);
                line-height: rem(26);
            }
        }
        @media screen and (max-width: 576px) {
            padding: 12px 20px;
            #redirect-btn-title, .redirect-btn-title {
                font-size: 0.9rem;
                line-height: 1.4rem;
            }
        }
    }
    &.bright {
        #home-heading, #home-text {
            color: $col-white;
        }
        #redirect-btn {
            color: $col-black-1;
            background-color: $col-beige;
            border: 1px solid $col-beige;
            #redirect-btn-icon {
                background-image: url("../img/icons_platforms/right_arrow_black.svg");
                background-repeat: no-repeat;
            }

        }
    }
    .navbar-toggler {
        margin-right: 6px;
    }
    .navbar-toggler, .logo-black {
        pointer-events: auto;
        touch-action: auto;
    }
    .platform-buttons {
        border: 1px solid #ffffff;
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.scroll-snapping-item {
    scroll-snap-align: start;
    display: flex;
    flex-flow: column;
    justify-content: center;
    min-height: 100vh;
}

// Big O mask

#home-big-o, .mask-container {
    width: 600px;
    position: absolute;
    height: 100%;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    background-position-y: 100%;
    background-size: 270%;
    background-position-x: 100%;
    background-repeat: no-repeat;
    -webkit-mask-image: url("../img/masks/zero.svg");
    -webkit-mask-size: 40vh;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: 100% 50%;
    pointer-events: none;
    touch-action: none;
    transition: background-image 5ms ease-in;
    &.switch-img {
        animation-name: 'switch-images';
        animation-duration: 0.7s;
        animation-delay: 0.5s;
        animation-fill-mode: both;
    }
}

#home-big-o-filter {
    width: 600px;
    position: absolute;
    height: 100%;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255,255,255);
    background-size: cover;
    -webkit-mask-image: url("../img/masks/zero.svg");
    -webkit-mask-size: 40vh;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: 100% 50%;
    pointer-events: none;
    touch-action: none;
    opacity: 0.4;
}
#home-big-o, #home-big-o-filter, .mask-container {
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

#home-big-o, #home-big-o-filter {
    @media screen and (max-height: 699px) {
        display: none !important;
    }
}


.pagination-inside {
    max-width: 1500px !important;
}

#home-fixed-container {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    touch-action: none;
}

#home-relative-container {
    position: relative;
    height: 100%;
    pointer-events: none;
    touch-action: none;
}

#home-nav {
    width: 100%;
    height: 100px;
}

#home-text-container {
    position: absolute;
    width: 100%;
    z-index: 2;
    transform: translateY(-50%);
    top: calc(46% + 100px);
    @media screen and (max-width: 575px) {
        top: calc(46% + 50px);
    }
    @media screen and (max-height: 699px) {
        top: calc(46% + 50px);
    }
}

#home-platform-btn-container {
    width: 100%;
    pointer-events: none;
    touch-action: none;
}

.home-text * {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;

}

#home-text * {
    @media screen and (max-height: 699px) {
        font-size: 1rem;
        line-height: 1.45rem;
    }
}

#home-carousel-indicators-container {
    @media screen and (min-width: 576px) {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: auto;
        touch-action: auto;
        .home-carousel-indicators {
            cursor: pointer;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #FFFFFF;
            margin-bottom: 25px;

            &.active {
                width: 12px;
                height: 12px;
                background-color: #C00000;

            }
        }
    }
    @media screen and (max-height: 576px) {
        display: none;
    }
}

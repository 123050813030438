/* news card*/
#news-page-main, .selected-news-block {
    .card {
        position: relative;
        border-radius: 0;
        border: none;

        .card-img-top {
            height: 435px;
            object-fit: cover;
            border: none;
            border-radius: 0;
        }

        &.three-in-row {
            .card-img-top {
                height: 288px;
            }
        }
    }

    .card-body, .card-no-image {
        &.bg-beige {
            color: $col-black-1;
        }

        &.bg-green, &.bg-dark-grey {
            color: white;
        }
    }

    .card-no-image {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 40px;
        min-height: 435px;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 174px -74px;
        @media screen and (min-width: 1200px) {
            background-size: 50%;
        }

        &.bg-beige {
            background-image: url('../img/masks/zero-trans-5.svg');
        }

        &.bg-green, &.bg-black {
            background-image: url('../img/masks/zero-trans-2.svg');
        }

        .card-heading {
            @media screen and (min-width: 768px) {
                font-size: rem(42);
                line-height: rem(48);
            }
        }
    }
    .download-arrow {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 58px;
        height: 58px;
    }

    .card-body {
        position: relative;
        font-family: "Roboto", sans-serif;
        line-height: 34px;
        font-size: 24px;
        padding: 40px;
    }


    .bg-image-green {
        background-color: $col-green;
        background-image: url("../img/news_background_green.png");
    }

    .bg-image-dark {
        background-color: $col-black-1;
        background-image: url("../img/news_background_black.png");
    }

    .bg-image-bright {
        background-color: $col-beige;
        background-image: url("../img/news_background_beige.png");
    }

    .text-dark {
        color: $col-black-1;
    }

    .text-bright {
        color: $col-white;
    }

    .card-heading {
        font-family: "PPAgrandirText-Bold", serif;
        line-height: rem(40);
        font-size: rem(32);
    }

    .news-card-text {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        line-height: rem(32);
        font-size: rem(24);
        text-overflow: ellipsis;
    }

    .card-img-top {
        width: 100%;
        height: 80%;
    }

    .card .news-arrow-right {
        position: absolute;
        bottom: 24px;
        right: 24px;
        width: 19px;
        height: 16px;
    }

    .news-card-date {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        position: absolute;
        bottom: 0;
        left: 40px;
    }
}

.selected-news-block {
    .card {
        .card-img-top {
            height: 300px;
        }
    }
}

/* pagination*/
.page-link {
    border: none;
    color: $col-black-1;
    padding: 12px 24px;
    margin: 12px;
    img {
        width: 19px;
        height: 19px;
    }
    &:hover {
        color: $col-black-1;
    }
    &:focus:not(:focus-visible) {
        outline: 0 !important;
        box-shadow: none !important;
    }
}
.active > .page-link {
    background-color: $col-black-1;
    border-color: none;
    color: #ffffff;
}
.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

#news-video-modal {
    .modal-content {
        border: 6px solid white;
    }
    .modal-body {
        height: 59vh;
    }
    .modal-header {
        border-bottom: initial;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
}

#news-image-modal {
    .card-heading {
        margin-top: 1.5rem;
    }
}

.contains-video {
    iframe {
        visibility: hidden;
        height: 0;
        width: 0;
    }
}

.blog-article-main-img {
    &.vertical {
        width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 828px;
        object-position: center;
    }
    &.horizontal{
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 828px;
        object-position: center;
    }
}

.branches-list-module {
    color: $col-black-1;
    .right-text-container {
        p, a, ul, span {
            &:last-child {
                margin-bottom: initial;
            }
        }
    }
    .office-address-cards-container {
        @media screen and (min-width: 1550px) {
            padding-left: inherit;
            padding-right: inherit;
        }
    }

    .office-address-card-wrapper {
        &.decor .row {
            background-color: #FFFFFF;
            opacity: 0.7;
            width: 100%;
            border-radius: 32px;
            .image-col-decor {
                background: url("../img/masks/zero-beige.svg");
                background-repeat: no-repeat;
                background-size: 200px;
                background-position-x: center;
                background-position-y: 30px;
            }
        }
        //display: flex;
        //justify-content: flex-start;
        border-radius: 32px;
        position: relative;
        @media screen and (min-width: 1550px) {
            &:nth-child(odd) {
                padding-left: 0;
            }
            &:nth-child(even) {
                padding-right: 0;
            }
        }
        .office-address-card {
            //height: 240px;
            padding: 35px 15px 35px 35px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: flex-start;
            flex-direction: column;
            gap: 20px;
            .office-address-card-heading {
                font-family: "PPAgrandirText-Bold", sans-serif;
                font-size: 24px;
                font-weight: bold;
                line-height: 16px;
                color: $col-grey;
            }
            .office-address-card-text {
                word-break: break-word;
                a, p {
                    display: block;
                    font-size: rem(16);
                    line-height: rem(26);
                    font-family: "Roboto", sans-serif;
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
        .office-address-card-arrow-btn {
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            img {
                width: 40px;
                height: 40px;
            }
        }
        .text-col-decor {
            border-bottom-right-radius: 32px;
            border-bottom-left-radius: 32px;
            background-color: #FFFFFF;
            @media screen and (min-width: 768px) {
                border-top-left-radius: 32px;
                border-bottom-right-radius: initial;
            }
        }
    }

    .office-image-card-wrapper {
        img {
            border-top-right-radius: 32px;
            border-top-left-radius: 32px;
            @media screen and (min-width: 768px) {
                border-top-right-radius: 32px;
                border-bottom-right-radius: 32px;
                border-top-left-radius: initial;
            }
        }
        .office-image-card {
            width: 100%;
            height: 300px;
            object-fit: cover;
            object-position: top;
        }
    }
}

.no-image-decor {
    height: 300px;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    @media screen and (min-width: 768px) {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
        border-top-left-radius: initial;
    }
    background-color: #f9f7f5;
    background-image: url(../img/masks/zero-beige.svg);
    background-repeat: no-repeat;
    background-size: 200px;
    background-position-x: center;
    background-position-y: 30px;
}

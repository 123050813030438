.image-bar-block {
    .rounded-corner-image-block {
        max-height: 420px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .rounded-border-right,  .rounded-border-left {
            overflow: hidden;
        }
        @media screen and (min-width: 576px) {
            .rounded-border-right {
                border-radius: 300px / 0 300px 300px 0;
            }
            .rounded-border-left {
                border-radius: 300px / 300px 0 0 300px;
            }
        }
    }
}

.logo-black {
    max-width: 227px;
    width: 100%;
    @media screen and (max-width: 575px) {
        max-width: 150px;
    }
}

.navigation-black {
    width: 30px;
    max-height: 30px;
}

.header-with-image, .header-without-image {
    .platform-buttons {
        border: 1px solid #ffffff;
        padding: 6px 16px;
        &:hover {
            background-color: #ffffff;
        }
        &.active {
            border: 1px solid #ffffff;
            background-color: #ffffff;
        }
    }
    &.bright {
        .platform-buttons {
            border: 1px solid #ffffff;
            background-color: #ffffff;
            padding: 6px 16px;
        }
    }
}

.header-without-image .banner-nav-container {
    div:nth-child(odd) {
        @media screen and (min-width: 992px) and (max-width: 1199px) {
            flex: 0 0 auto;
            width: 41.66666667%;
        }
        @media screen and (min-width: 1200px) and (max-width: 1399px) {
            flex: 0 0 auto;
            width: 33.33333333%;
        }
    }
}

.platform-buttons {
    //padding: 10px 40px 10px 0;
    border: 1px solid $col-light-grey;
    padding: 6px 16px;
    pointer-events: auto;
    touch-action: auto;
    white-space: nowrap;
    @media screen and (max-width: 575px) {
        white-space: initial;
    }
    //@media screen and (max-width: 1400px) {
    //    padding: 10px 20px;
    //}
    span {
        img {
            width: rem(16);
            height: rem(16);
        }
    }

    .platform-btn-icons {
        display: inline-block;
    }

    .platform-btn-icons-hover {
        display: none;
    }

    &:hover {
        .platform-btn-icons {
            display: none;
        }

        .platform-btn-icons-hover {
            display: inline-block;
        }
    }
}

.language {
    font-size: rem(20);
    position: relative;
    top: 0;
    &.nav-link {
        color: $col-red;
    }
}

.banner-nav-container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: min-content;
}

.header-without-image {
    &.bright {
        .mask-container-color {
            opacity: 66%;
        }
    }
    .banner-nav-container {
        margin-top: 100px;
    }
    &.blog-article-header {
        .container-fluid {
            background-image: initial;
        }
    }
    .mask-container-color {
        width: 100%;
        position: absolute;
        height: 100%;
        -webkit-mask-size: 62vh;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: 85% 105%;
        top: 0;
        left: 0;
        @media screen and (min-width: 1536px) and (max-width: 1919px) {
            -webkit-mask-size: 48vh;
        }
        @media screen and (min-width: 1440px) and (max-width: 1535px) {
            -webkit-mask-size: 46vh;
        }
        @media screen and (min-width: 1366px) and (max-width: 1439px) {
            -webkit-mask-size: 50vh;
        }
    }
}



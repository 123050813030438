.management-team-card-text-container {
    @media only screen and (min-width: 576px) {
        height: 175px;
    }
}

.management-team-member-heading {
    padding: 100px 0 0 0;
    @media only screen and (max-width: $screen-sm) {
        padding: 40px 0;
    }
}
.management-team-card-img-container {
    background: url("../img/team_member_empty_photo_beige.svg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    aspect-ratio: 1/1;
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        object-fit: cover;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        height: 100%;
    }
}
.management-team-card-name {
    font-size: rem(16);
    line-height: rem(22);
    font-weight: 500;
    color: black;
    text-align: center;
}
.management-team-card-field {
    font-size: rem(13);
    line-height: rem(12);
    font-weight: 700;
    color: $col-grey;
    text-align: center;
}

@media only screen and (max-width: 575px) {
    .team-card-heading-wrapper {
        .heading-text.team-member-heading {
            text-align: center;
        }
    }
}



// sustainable equities card section
.sustainable-equities-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 4px solid #0A4646;
    text-align: center;
    --bs-card-border-color: initial;
    --bs-card-border-radius: initial;
    padding: 2.5rem;
    @media screen and (max-width: 575px) {
        padding: 2.5rem 1.2rem;
    }
}
.logo-cards {
    height: 50px;
    img {
        height: 100%;
        width: 100%;
    }
    padding-bottom: 10px;
}

/* NIXA SASS
=========================== */
// Made by geniuses at Nixa / www.nixa.ca //


// functions
//convert px to rem
@function rem($value) {
    @return calc($value / 16) + rem;
}


// color variables
$col-white: #FFFFFF;
$col-black: #000000;
$col-black-1: #262627;
$col-black-2: #FFFFFF00;
$col-red: #C00000;
$col-dark-red: #B12318;
$col-green: #0A4646;
$col-dark-grey: #363638;
$col-semi-dark-grey: #4E4E4E;
$col-grey: #6B7979;
$col-light-grey: #CFD0E2;
$col-gray-1: #E4EDEC;
$col-gray-2: #EDE4DE;
$col-gray-3: #E4E9ED;
$col-beige: #EDE4DE;


// breakpoints
$xs: 400px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;


//fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "fonts";

//Modules

// Components


// Base
@import "_navs/header_nav.scss";
@import "header";
@import "footer";

// Pages
@import "pages/home";
@import "pages/our_team_page";
@import "pages/contact_page";
@import "pages/news_blog_page";
@import "pages/single_blog_page";
@import "pages/sustainable_infrastructure_page";
@import "pages/agri_food_private_equity_page";
@import "pages/sustainable_equities_page";
@import "pages/404";

// Common
@import "spacing";
@import "colors";
@import "shared";

// Streams

@import "streams/title_left_text_right_block";
@import "streams/branches_list_module";
@import "streams/image_bar_block";
@import "streams/list_bar_block";
@import "streams/team_members_block";
@import "streams/three_squares_without_icons_block";
@import "streams/video_block";
@import "streams/cell_block";
@import "streams/horizontal_flowchart_block";


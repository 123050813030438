.quick-filters-container:nth-child(odd) {
    @media screen and (max-width: 575px) {
        .quick-filters {
            background-color: $col-dark-grey;
            &.active {
                background-color: #ffffff;
                border-bottom: 1px solid $col-semi-dark-grey;
                border-top: 1px solid $col-semi-dark-grey;
            }
        }
    }
}

.quick-filters,
.quick-filters-empty {
    overflow: hidden;
    background-color: $col-semi-dark-grey;
    color: white;
    font-size: rem(24);
    line-height: rem(34);
    padding: 10% 10% 7% 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 575px) {
        flex-direction: row;
        align-items: center;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        padding: 6% 10%;
    }
    @media screen and (min-width: 576px) {
        margin-top: calc(var(--bs-gutter-x) * 0.5);
        margin-bottom: calc(var(--bs-gutter-x) * 0.5);
        border-radius: 32px;
        min-height: 154px;
    }
    span {
        color: $col-dark-red;
    }
    .arrow-icon-right {
        background: url("../img/icon_arrow_white_right.svg");
        background-repeat: no-repeat;
        height: 19px;
        width: 19px;
    }
    &.active {
        color: $col-black-1;
        background-color: white;
        @media screen and (max-width: 575px) {
            border-bottom: 1px solid $col-dark-grey;
            border-top: 1px solid $col-dark-grey;
        }
        .arrow-icon-right {
            background: url("../img/close_icon.svg");
            background-repeat: no-repeat;
        }
    }
    .quick-filters-img {
        opacity: 0.4;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
        object-fit: cover;
    }
    .quick-filters-empty-logo {
        background: url("../img/masks/zero.svg");
        background-repeat: no-repeat;
        background-size: 95px;
        opacity: 0.3;
        position: relative;
        margin-top: -47px;
        margin-left: auto;
        height: 186px;
        width: 110px;
    }
    &:hover {
        @media screen and (min-width: 576px) {
            position: relative;
            background-color: $col-dark-grey;
            .quick-filters-img {
                display: block;
            }
            a,
            p {
                position: relative;
            }
            &.active {
                background-color: white;
                .quick-filters-img {
                    display: none;
                }
            }
        }
    }
}

.quick-filters-empty {
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    &:hover {
        background-color: $col-semi-dark-grey;
    }
    @media screen and (max-width: 575px) {
        display: none;
    }
}

.search-btn {
    background: url("../img/search_icon.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    border: initial;
    position: absolute;
    right: 15px;
    top: rem(5);
    bottom: 0;
    margin: auto 0;
}

.team-card,
#team-member-modal {
    .team-card-img-container {
        width: 100%;
        aspect-ratio: 1 / 1;
        overflow: hidden;
        @media only screen and (max-width: 767px) {
            &.team-card-heading-wrapper {
                height: initial;
            }
        }
        background: url("../img/team_member_empty_photo_beige.svg") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        @media only screen and (max-width: 575px) {
            overflow: hidden;
        }
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            //@media only screen and (min-width: 576px) {
            //    height: 100%;
            //}
        }
        &.team-card-heading-wrapper {
            padding: 32px;
            background: $col-beige;
            &.bg-initial {
                background: initial;
            }
        }

    }
    .team-card-img-container, .management-team-card-text-container {
        h3 {
            @media only screen and (min-width: 1200px) {
                font-size: rem(40);
                line-height: rem(50);
            }
            font-size: rem(32);
            line-height: rem(42);
            font-family: "PPAgrandirText-Bold", sans-serif;

        }
    }
    .management-team-card-text-container {
        h3 {
            @media only screen and (min-width: 1200px) and (max-width: 1550px){
                font-size: rem(38);
                line-height: rem(48);
            }
        }
    }
    .team-card-text {
        font-family: "Roboto", sans-serif;
        .team-card-name {
            font-size: rem(24);
            line-height: rem(32);
            font-weight: 500;
            color: black;
            text-align: center;
        }
        .team-card-field {
            font-size: rem(14);
            line-height: rem(18);
            font-weight: 700;
            color: $col-grey;
            text-align: center;
        }
        .team-card-position {
            font-size: rem(16);
            line-height: rem(20);
            font-weight: 400;
            color: black;
        }
    }
}

.team-member-modal-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.team-member-modal-img-container,
.team-member-modal-img {
    border-top-left-radius: 26px;
    @media screen and (min-width: 1200px) {
        border-bottom-left-radius: 26px;
    }
    @media screen and (max-width: 1199px) {
        border-top-right-radius: 26px;
    }
}

#team-member-modal {
    .modal-dialog {
        @media screen and (min-width: 1200px) {
            --bs-modal-width: 1474px;
        }
        --bs-modal-border-radius: 28px;
    }
    .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .team-member-modal-img-container {
        background: url("../img/photo_template1.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .team-member-modal-text-container {
        border-bottom-right-radius: 26px;
        @media screen and (min-width: 1200px) {
            border-top-right-radius: 26px;
        }
        @media screen and (max-width: 1199px) {
            border-bottom-left-radius: 26px;
        }
    }
    .modal-divider {
        display: inline-block;
        width: 100%;
        height: 4px;
        background-color: $col-dark-red;
        @media screen and (min-width: 1200px) {
            max-width: 400px;
        }
    }
    .team-card-description, .team-card-position {
        font-family: "PPAgrandir-Regular", sans-serif;
        font-size: rem(18);
        line-height: rem(33);
        text-decoration: initial;
        color: $col-black-1;
        text-align: left;
    }
    .team-card-description {
        @media screen and (min-width: 1200px) {
            max-height: 216px;
            overflow-y: auto;
        }
    }
    .team-card-text {
        font-family: "Roboto", sans-serif;
        .team-card-name {
            font-size: rem(30);
            line-height: rem(38);
            font-weight: 500;
            color: black;
            text-align: left;
        }
        .team-card-field {
            font-size: rem(22);
            line-height: rem(26);
            font-weight: 700;
            color: $col-grey;
            text-align: left;
        }
        .team-card-position {
            font-family: "PPAgrandir-Regular", sans-serif;
            font-size: rem(18);
            line-height: rem(33);
            text-decoration: initial;
            color: $col-black-1;
            text-align: left;
        }
        @media only screen and (max-width: 1199px) {
            .team-card-name, .team-card-field, .team-card-position {
                text-align: center;
            }
        }

    }
}

.team-card {
    cursor: pointer;
    &.team-title {
        .team-card-img-container {
            @media only screen and (max-width: 767px) {
                aspect-ratio: initial;
            }
        }
    }
}

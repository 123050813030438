@font-face {
    font-family: "PPAgrandir-Regular";
    src: url("../fonts/Agrandir/PPAgrandir-Regular.otf") format("opentype");
}

@font-face {
    font-family: "PPAgrandir-NarrowRegular";
    src: url("../fonts/Agrandir/PPAgrandir-NarrowRegular.otf")
        format("opentype");
}

@font-face {
    font-family: "PPAgrandir-TightRegular";
    src: url("../fonts/Agrandir/PPAgrandir-TightRegular.otf") format("opentype");
}

@font-face {
    font-family: "PPAgrandirText-Bold";
    src: url("../fonts/Agrandir/PPAgrandirText-Bold.otf") format("opentype");
}

@font-face {
    font-family: "PPAgrandir-GrandHeavy";
    src: url("../fonts/Agrandir/PPAgrandir-GrandHeavy.otf") format("opentype");
}

@font-face {
    font-family: "PPAgrandir-GrandLight";
    src: url("../fonts/Agrandir/PPAgrandir-GrandLight.otf") format("opentype");
}

@font-face {
    font-family: "PPAgrandir-ThinItalic";
    src: url("../fonts/Agrandir/PPAgrandir-ThinItalic.otf") format("opentype");
}

@font-face {
    font-family: "PPAgrandir-WideBlackItalic";
    src: url("../fonts/Agrandir/PPAgrandir-WideBlackItalic.otf")
        format("opentype");
}

@font-face {
    font-family: "PPAgrandir-WideLight";
    src: url("../fonts/Agrandir/PPAgrandir-WideLight.otf") format("opentype");
}

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(35);
    color: $col-black;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.font-18 {
    font-size: rem(18);
    line-height: rem(26);
}

.font-24 {
    font-size: rem(24);
    line-height: rem(35);
    @media screen and (max-width: 1400px) {
        font-size: rem(20);
        line-height: rem(30);
    }
}

h2,
h4 {
    font-family: "PPAgrandir-Regular", sans-serif;
}

h1,
h1 a {
    //font-size: rem(60);
    //line-height: rem(72);
    font-size: 3rem;
    line-height: 3.5rem;
    font-family: "PPAgrandirText-Bold", sans-serif;
    &#home-heading {
        @media screen and (max-height: 699px) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
    span {
        color: $col-dark-red;
    }
    @media screen and (min-width: 1921px) {
        font-size: 2.4rem;
        line-height: 3rem;
    }
    @media screen and (max-width: 1700px) {
        font-size: 2.4rem;
        line-height: 3rem;
    }
    //@media screen and (max-width: 1536px) {
    //    font-size: rem(40);
    //    line-height: rem(56);
    //}
    @media screen and (max-width: 576px) {
        font-size: rem(32);
        line-height: rem(42);
    }
    color: $col-black;
    text-decoration: initial;
}

h2 {
    //font-size: rem(48);
    //line-height: rem(60);
    font-size: 2.4rem;
    line-height: 3rem;
    font-family: "PPAgrandirText-Bold", sans-serif;
    span {
        color: $col-dark-red;
    }
    @media screen and (min-width: 1921px) {
        font-size: 2.1rem;
        line-height: 2.7rem;
    }
    @media screen and (max-width: 1700px) {
        font-size: 2.1rem;
        line-height: 2.7rem;
    }
    @media screen and (max-width: 1400px) {
        font-size: rem(32);
        line-height: rem(42);
    }
    @media screen and (max-width: 576px) {
        font-size: rem(24);
        line-height: rem(34);
    }

}

a {
    text-decoration: none;
    color: inherit;
    font-size: initial;
}

nav {
    .nav-link {
        font-family: "PPAgrandir-Regular", sans-serif;
        font-size: rem(18);
        color: $col-black-1;
    }
    .offcanvas-title {
        font-family: "Roboto", sans-serif;
        font-size: rem(18);
        color: $col-black-1;
    }
}

.platform-buttons {
    font-family: "PPAgrandirText-Bold", sans-serif;
    font-size: rem(16);
    line-height: rem(24);
    text-decoration: initial;
    color: $col-black-1;
}

#redirect-btn-title {
    font-family: "PPAgrandirText-Bold", sans-serif;
    font-size: rem(18);
    line-height: rem(33);
}

.quick-filters {
    p {
        @media screen and (max-width: 450px) {
            font-size: rem(20);
            line-height: rem(30);
        }
    }
}

.rich-text-container {
    p {
        margin-bottom: 0;
        padding-bottom: 1rem;
        a {
            margin-bottom: initial;
        }
    }
    h1,h2,h3,h4,h5,h6 {
        font-family: "PPAgrandirText-Bold", sans-serif;
        margin-bottom: 1rem;
    }
    a {
        color: $col-green;
        text-decoration: underline;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: rem(20);
        line-height: rem(35);
        margin-bottom: 1rem;
        display: inline-block;
    }
    a:hover {
        opacity: 0.75;
    }
    h2 {
        font-size: 48px;
        line-height: 60px;
    }
    h3 {
        font-size: 36px;
        line-height: 48px;
    }
    h4 {
        font-size: 28px;
        line-height: 38px;
    }
    h5 {
        font-size: 24px;
        line-height: 32px;
    }
    h6 {
        font-size: 18px;
        line-height: 24px;
    }
    @media screen and (max-width: 1200px) {
        h2 {
            font-size: 36px;
            line-height: 48px;
        }
        h3 {
            font-size: 28px;
            line-height: 38px;
        }
        h4 {
            font-size: 24px;
            line-height: 32px;
        }
    }
    blockquote {
        background: #f7f7f7;
        border-left: 4px solid #ccc;
        padding: 0.5em 20px;
        margin-bottom: 1rem;
    }
    ul, ol {
        margin-bottom: 1rem;
        display: inline-block;
        text-align: left;
    }
    ul li::marker {
        color: $col-green;
        font-size: 1.1em;
    }
    .richtext-image {
        &.full-width {
            align-self: center;
            margin-bottom: 2rem;
            &.horizontal {
                max-width: 100%;
                height: auto;
            }
            &.vertical {
                max-width: 100%;
                height: auto;
            }
        }
        &.left, &.right {
            &.horizontal {
                max-width: 100%;
                max-height: 464px;
                height: auto;
                margin-bottom: 1rem;
            }
            &.vertical {
                height: auto;
                margin-bottom: 1rem;
            }
        }
        &.left {
           align-self: start;
        }
        &.right {
            align-self: end;
        }
    }
}

.rich-text-container > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
}

.title-two-col-text-block {
    h2 {
        font-size: 44px;
        line-height: 54px;
    }

    h3 {
        font-size: 36px;
        line-height: 40px;
        font-family: "PPAgrandirText-Bold", sans-serif;
    }
    @media screen and (max-width: 1200px) {
        h2 {
            font-size: 36px;
            line-height: 40px;
        }
        h3 {
            font-size: 28px;
            line-height: 32px;
        }
    }
}

// Growth data display section
.growth-data-img {
    width: 140px;
    height: 140px;
    @media screen and (max-width: 1200px) {
        width: 80px;
        height: 80px;
    }
    img {
        width: auto;
        height: 100%;
    }
}
.growth-data {
    .growth-data-info {
        font-size: 60px;
        font-family: "PPAgrandirText-Bold", sans-serif;
        @media screen and (max-width: 1200px) {
            font-size: 48px;
        }
    }
    .growth-data-text {
        font-size: 30px;
        font-family: "PPAgrandir-Regular", sans-serif;
        @media screen and (max-width: 1200px) {
            font-size: 26px;
        }
    }
}

/* 404 */

body {
    &.template-404, &.template-500 {
        min-height: 100vh;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        #redirect-btn, .redirect-btn {
            color: $col-beige;
            background-color: $col-black-1;
            display: none;
            flex-direction: row;
            align-items: center;
            text-decoration: initial;
            padding: 15px 40px 15px 20px;
            cursor: pointer;
            margin-top: 2rem;
            pointer-events: auto;
            touch-action: auto;

            #redirect-btn-icon, .redirect-btn-icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-top: 4px;
                background-image: url("../img/icons_platforms/right_arrow_beige.svg");
                background-repeat: no-repeat;
                position: relative;
                padding-right: 20px;
                left: 0;
                transition: left 0.5s;
            }

            &:hover {
                #redirect-btn-icon, .redirect-btn-icon {
                    left: 10px;
                }
            }

            #redirect-btn-title, .redirect-btn-title {
                font-size: rem(20);
                line-height: rem(28);
                font-family: "PPAgrandirText-Bold", sans-serif;
                display: inline-block;
                padding-left: 18px;
            }

            @media screen and (max-width: 1400px) {
                padding: 12px 40px 12px 20px;
                #redirect-btn-title, .redirect-btn-title {
                    font-size: rem(18);
                    line-height: rem(26);
                }
            }
        }
        .header-without-image .container-fluid.content-404, .header-without-image .container-fluid.content-500  {
            background-image: url(../img/masks/zero-beige.svg);
            min-height: 70vh;
            background-repeat: no-repeat;
            background-size: 20%;
            background-position: 90% 0;
            @media screen and (min-width: 1200px) {
                background-size: 20%;
                background-position: 90% 0;
            }
            @media screen and (max-width: 992px) {
                min-height: initial;
            }
            @media screen and (max-width: 767px){
                background-image: initial;
            }
            @media screen and (min-height: 1366px) and (min-width: 992px){
                min-height: 37vh;
            }
        }
    }
}


.table {
    td {
        padding: 1rem;
        min-width: 200px;
        vertical-align: middle;
        @media only screen and (max-width: 1199px) {
            min-width: initial;
            text-align: center!important;
        }
        @media only screen and (max-width: 575px) {
            * {
                font-size: rem(15)!important;
            }
        }
        &.rich-text-container {
            p,a,li,span {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
        p {
            color: #545454;
            strong, b {
                color: $col-black-1;
            }
        }
        &:last-child {
            @media only screen and (max-width: 1199px) {
                display: none;
            }
        }
    }
}

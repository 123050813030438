.template-blog-page {
   .rich-text-container {
       .richtext-image {
           &.full-width {
               clear: both;
               object-position: center;
               margin-bottom: 2rem;
               &.horizontal {
                   width: 100%;
                   height: auto;
                   max-height: 828px;
                   object-fit: cover;
               }
               &.vertical {
                   width: 100%;
                   height: auto;
                   max-height: 828px;
                   object-fit: contain;
               }
           }
           &.left, &.right {
               &.horizontal {
                   max-width: 825px;
                   max-height: 464px;
                   object-fit: cover;
                   object-position: center;
                   width: 100%;
                   height: auto;
                   margin-bottom: 1rem;
               }
               &.vertical {
                   object-fit: contain;
                   height: auto;
                   margin-bottom: 1rem;
               }
           }
           &.left {
               clear: both;
               float: left;
               margin-right: 30px;
           }
           &.right {
               clear: both;
               float: right;
               margin-left: 30px;
           }
           @media screen and (max-width: 1439px) {
               &.left, &.right {
                   &.horizontal {
                       max-width: 50%;
                   }
               }
           }
           @media screen and (max-width: 767px) {
               &.left, &.right {
                   &.horizontal {
                       max-width: 100%;
                       float: initial;
                       margin-right: initial;
                       margin-left: initial;
                       margin-bottom: 1rem;
                   }
               }
               &.full-width {
                   margin-bottom: 1rem;
               }
           }
       }
   }
}

.relative {
    position: relative;
}

.overflow-x-auto {
    overflow-x: auto;
}

.form-select {
    background-image: url("../img/select_input_icon.svg");
}

.form-control {
    &::placeholder {
        color: $col-grey;
    }
    &::-ms-input-placeholder {
        color: $col-grey;
    }
}

.form-control,
.form-select {
    border: solid 1px $col-light-grey;
    border-radius: initial;
    font-size: rem(17);
    line-height: rem(23);
    font-family: "Roboto", sans-serif;
    color: $col-grey;
    padding: rem(10);
}

.col-5-units {
    @media screen and (min-width: 1400px) {
        flex: 0 0 auto;
        width: 20%;
    }
}

.search-form {
    input::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
}

.pointer {
    cursor: pointer;
}

// Masks styling

.mask-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    opacity: 0.2;
    .masks {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.z-index-initial {
    z-index: initial;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.hidden {
    visibility: hidden;
}

.template-our-team-page {
    .team-card {
        &:last-child {
            border-top-right-radius: initial;
            border-bottom-right-radius: initial;
            img {
                border-top-right-radius: initial;
                border-bottom-right-radius: initial;
            }
            .team-card-img-container {
                border-top-right-radius: initial;
                border-bottom-right-radius: initial;
            }
        }
    }
}

// flex page team member heading styles
@media only screen and (min-width: $screen-md) {
    //.team-card {
    //    &:last-child {
    //        border-top-right-radius: 40px;
    //        border-bottom-right-radius: 40px;
    //        img {
    //            border-top-right-radius: 40px;
    //            border-bottom-right-radius: 40px;
    //        }
    //        .team-card-img-container {
    //            border-top-right-radius: 40px;
    //            border-bottom-right-radius: 40px;
    //        }
    //    }
    //}
}

.team-card-heading-wrapper {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    @media only screen and (max-width: 767px) {
        border-top-left-radius: 40px;
        border-bottom-left-radius: initial;
        border-top-right-radius: 40px;
    }
    img {
        border-top-left-radius: 40px !important;
        border-bottom-left-radius: 40px !important;
    }

    @media only screen and (max-width: $screen-sm) {
        //height: 100px !important;
        border-top-left-radius: 75px;
        border-top-right-radius: 75px;
        img {
            border-top-left-radius: 75px !important;
            border-top-right-radius: 75px !important;
            border-bottom-left-radius: 0 !important;
        }
    }
    .team-member-heading {
        //position: absolute;
        //left: 32px;
        //bottom: 32px;
        @media only screen and (max-width: $screen-sm) {
            br {
                display: none;
            }
        }
    }
}

// Distributed energy card section
.card-heading-text {
    font-family: "PPAgrandirText-Bold", sans-serif;
    font-size: rem(24);
}
.card-body-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: rem(20);
    display: flex;
    flex-direction: column;
}

.card-body-text > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
}

.bottom-footer > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
}

//section background L shapes
.bg-u-shape-img-wrapper {
    width: 100vw;
    height: 40vh;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    .u-shape-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media only screen and (max-width: $screen-sm) {
            display: none;
        }
    }
}

.u-shape-img-row {
    position: absolute;
    height: 100%;
    width: 50%;
}

.u-shape-img {
    height: auto;
    width: auto;
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    @media only screen and (max-width: $screen-lg) {
        display: none;
    }
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.image-block-container {
    max-height: 60vh;
    overflow: hidden;
}

.col-xl-5-units {
    @media screen and (min-width: 1200px) {
        flex: 0 0 auto;
        width: 20%;
    }
}

.col-xxl-2 {
    @media only screen and (min-width: 1440px) {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
}

.h-flip {
    transform: scaleX(-1);
    -ms-filter: "FlipH";
}

.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-270 {
    transform: rotate(270deg);
}


